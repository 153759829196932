import React from "react"
import { Element as ScrollableAnchor } from "react-scroll"
import { useI18n } from "next-localization"
import cn from "clsx"
import dynamic from "next/dynamic"

import { useGlobalState } from "@app/contexts"

import { Container } from "@app/ui/container"
import { WatermarkLoader } from "../watermark-loader"
import { HidingScene } from "@app/features/hiding-media-files/components"

const Masonry = dynamic(() => import("@app/features/masonry/components").then((mod) => mod.Masonry), {
  ssr: false,
})
const MasonryHorizontal = dynamic(
  () => import("@app/features/masonry/components").then((mod) => mod.MasonryHorizontal),
  {
    ssr: false,
  }
)

import styles from "./scene.module.scss"

function Scene({
  name,
  id,
  description,
  mediaFiles,
  settings,
  layout,
  isWithoutScenes,
  isDownloadEnabled,
  isPhotosInProgress,
  isCanBeHidden,
  isAllMediaFilesHidden,
  className,
  isHiddenScene,
}) {
  const isMediaFilesExist = mediaFiles.length > 0
  const isDescriptionVisible = settings.isDescriptionVisible
  const idScene = !isHiddenScene ? id : `hidden-${id}`

  const {
    userAgent: { isMobile },
  } = useGlobalState()

  const i18n = useI18n()

  const [isHover, setIsHover] = React.useState(false)
  const isShowHidingBtn = isMobile || isHover || isHiddenScene
  function handleMouseEnter() {
    setIsHover(true)
  }
  function handleMouseLeave() {
    setIsHover(false)
  }

  return (
    <ScrollableAnchor name={idScene} className={className}>
      <section
        className={cn(styles["scene"], {
          [styles["without-title"]]: isWithoutScenes,
        })}
        id={id}
      >
        <Container className={styles["container"]}>
          {!isWithoutScenes && name && (
            <h1
              className={cn(styles["title"], {
                [styles["with-photo"]]: isMediaFilesExist,
                [styles["without-photo"]]: !isMediaFilesExist,
                [styles["with-description"]]: isDescriptionVisible,
                [styles["with-hiding"]]: isCanBeHidden,
                [styles["is-hover-and-can-hidden"]]: isShowHidingBtn,
              })}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <span className={styles["title-text"]}>{name}</span>
              {isCanBeHidden && (
                <HidingScene
                  id={id}
                  mediaFiles={mediaFiles}
                  isMediaFilesExist={isMediaFilesExist}
                  isHiddenScene={isHiddenScene}
                  isAllMediaFilesHidden={isAllMediaFilesHidden}
                ></HidingScene>
              )}
            </h1>
          )}
          {isDescriptionVisible && description && (
            <p
              className={cn(styles["description"], {
                [styles["with-photo"]]: isMediaFilesExist,
                [styles["without-photo"]]: !isMediaFilesExist,
              })}
            >
              {description}
            </p>
          )}
          {!isMediaFilesExist && !isPhotosInProgress && (
            <p
              className={cn(styles["no-photos-message"], {
                [styles["with-description"]]: isDescriptionVisible,
                [styles["without-description"]]: !isDescriptionVisible,
              })}
            >
              {i18n.t("galleryPage.noPhotos")}
            </p>
          )}
          {layout === "vertical" && (
            <Masonry
              mediaFiles={mediaFiles}
              isDownloadEnabled={isDownloadEnabled}
              isCanBeHidden={isCanBeHidden}
              isHiddenScene={isHiddenScene}
            />
          )}
          {layout === "horizontal" && (
            <MasonryHorizontal
              mediaFiles={[...mediaFiles]}
              isDownloadEnabled={isDownloadEnabled}
              isCanBeHidden={isCanBeHidden}
              isHiddenScene={isHiddenScene}
            />
          )}
          {isMediaFilesExist && isPhotosInProgress && (
            <WatermarkLoader text={i18n.t("galleryPage.watermark_in_progress")} />
          )}
        </Container>
      </section>
    </ScrollableAnchor>
  )
}

export { Scene }
